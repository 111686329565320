<template>
  <div class="game_contain wb-100 hb-100">
    <div class="game_head center wb-100 h-100 relative">
      <div class="back fz-30 t-w pointer" @click="handleBack">
        <i class="el-icon-arrow-left fz-40"></i>
        <span class="ml-17">返回</span>
      </div>
      <div class="fz-30 t-w">{{ title }}</div>
    </div>
    <div class="livebox">
      <video
        class="video"
        id="playerVideo"
        preload="auto"
        width="100%"
        height="100%"
        playsinline
        autoplay
        muted
        webkit-playsinline
        controls
        disablePictureInPicture
        controlsList="nodownload  noplaybackrat noplaybackrate"
        style="width: 100%; height: 100%;object-fit: cover;"
      ></video>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../common/common";
import TCPlayer from "tcplayer.js";
import "tcplayer.js/dist/tcplayer.min.css";
export default {
  data() {
    return {
      imageUrl,
      width: "",
      height: "",
      title: "直播回放",
      video_url: "",
      pull_url: "",
      player: ''
    };
  },
  created() {
    this.getQuery();
  },
  mounted(){
    this.pullUrl()
  },
  destroyed() {
    this.disposeVideo()
  },
  methods: {
    pullUrl(){
        console.log(this.pull_url,'this.pull_url');
        if(this.pull_url) {
            this.player = TCPlayer('playerVideo', {})
            this.player.src(this.pull_url)
        }
    },
    disposeVideo(){
        this.player.dispose()
    },
    handleBack() {
      this.$router.go(-1);
    },
    getQuery() {
      const { title } = this.$route.query;

      this.title = title;
      this.pull_url = window.localStorage.getItem("pull_url");
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.game_contain {
  background-color: #f5f6fa;
  .game_head {
    background-color: #212526;
  }
}

.livebox {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #000;
}
</style>
