<template>
  <div class="live_contain">
    <div class="game_head center wb-100 h-100 relative">
      <div class="back fz-30 t-w pointer" @click="handleBack">
        <i class="el-icon-arrow-left fz-40"></i>
        <span class="ml-17">返回</span>
      </div>
      <div class="fz-30 t-w" @click="getLiveStatus">{{ title }}</div>
      <div class="close fz-30 pointer t-w" @click="handleBack">关闭直播</div>
    </div>
    <div class="live_content">
      <div id="local_video" :style="containStyle"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pushUrl: "",
      id: "",
      match_id: "",
      title: "",
      livePusher: null,
      deviceManager: null,
      // 前置摄像头id
      frontCameraId: null,
      // 后置摄像头id
      backCameraId: null,
      // 摄像头id
      cameraId: null,
      containStyle: {
        width: "100%",
      },
      isCloseLive: false,
      cameraIdList: []
    };
  },
  created() {
    this.getQuery();
    this.isCloseLive = false;
  },
  mounted() {
    window.addEventListener("popstate", this.onPopState);
    window.addEventListener("beforeunload", this.onPopState);

    this.setViewContain();
    this.liveInit();
  },
  beforeDestroy() {
    if (this.isCloseLive) {
      this.backLive();
    }
    // 在组件销毁前，移除 popstate 事件监听
    window.removeEventListener("popstate", this.onPopState);
    window.removeEventListener("beforeunload", this.onPopState);
  },
  computed: {
    applyCamerId() {
      const { frontCameraId, backCameraId, cameraId } = this;
      return backCameraId ? backCameraId : cameraId;
    },
  },
  methods: {
    onPopState(e) {
      if (!this.isCloseLive) {
          this.backLive();
      }
    },
    closeLive() {
      this.$confirm("此操作将关闭直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.backLive();
          this.isCloseLive = true;
          this.$message({
            type: "success",
            message: "直播关闭成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    setViewContain() {
      // 获取设备宽度
      const width = window.innerWidth || 1920;

      const height = (1080 * width) / 1920;

      this.containStyle = {
        width,
        height,
      };
    },
    getLiveStatus() {
      // 获取直播状态
      const isPushing = this.livePusher.isPushing();
      console.log(isPushing, "isPushing");
    },
    getQuery() {
      const { id, match_id, title } = this.$route.query;
      const pushUrl = window.localStorage.getItem("pushUrl");
      this.pushUrl = JSON.parse(decodeURIComponent(pushUrl));
      this.id = id;
      this.match_id = match_id;
      this.title = title;
    },
    getCamerId() {
      const that = this;
      const videoDevices = [];
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === "videoinput") {
              videoDevices.push(device);
            }
          });
          console.log(devices, "devices=====================");
          console.log(videoDevices, "videoDevices");
          // if (videoDevices.length > 1) {
          //   that.frontCameraId = videoDevices[1].deviceId;
          //   that.backCameraId = videoDevices[0].deviceId;
          // } else {
          //   that.cameraId = videoDevices[0].deviceId;
          // }
          // 你可以使用 frontCameraId 或 backCameraId 来切换摄像头
          console.log("Front Camera ID:", that.frontCameraId);
          console.log("Back Camera ID:", that.backCameraId);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },
    async liveInit() {
      // this.getCamerId();
      const that = this;
      let isLandscape = true;
      const width1 = window.innerWidth || 1920;
      const height1 = (1080 * width1) / 1920;

      const height2 = window.innerHeight || 1080;
      const width2 = (1080 * height2) / 1920;

      let width = "";
      let height = "";

      let cha = Math.abs(height2 - width2) / 2;

      if (width1 > height2) {
        // 横屏-显示屏
        width = "100%";
        height = height1 + "px";
        isLandscape = true;
      } else {
        // 竖屏显示屏
        width = height2 + "px";
        height = width2 + "px";
        isLandscape = false;
      }

      console.log(isLandscape, "isLandscape");

      this.livePusher = new TXLivePusher();
      this.livePusher.setRenderView("local_video");
      this.livePusher.videoView.muted = true;
      // 横竖屏样式显示
      // this.livePusher.videoView.style = `object-fit: cover; width: ${width}; height: ${height};transform:  translate(-${isLandscape ? 0 : cha}px, ${isLandscape ?0 : cha}px) rotate(${isLandscape ? 0 : 90}deg)`;
      this.livePusher.videoView.style = `object-fit: cover; width: 100%; height: ${height1}px;`;
      this.livePusher.videoView.preload = "auto";
      this.livePusher.videoView.playsinline = true;
      this.livePusher.videoView.disablePictureInPicture = true;
      this.livePusher.videoView.controlsList =
        "nodownload  noplaybackrat noplaybackrate";
      // object-fit

      TXLivePusher.checkSupport().then(function (data) {
        // 是否支持WebRTC
        if (data.isWebRTCSupported) {
          console.log("WebRTC Support");
        } else {
          // console.log("WebRTC Not Support");
          alert('您的此浏览器不支持WebRTC，请切换浏览器')
        }
        // 是否支持H264编码
        if (data.isH264EncodeSupported) {
          console.log("H264 Encode Support");
        } else {
          // console.log("H264 Encode Not Support");
          alert('您的此浏览器不支持H264编码，请切换浏览器')
        }
        // 是否支持获取媒体设备及媒体流
        if (data.isMediaDevicesSupported) {
          console.log("isMediaDevicesSupported Support");
        } else {
          // console.log("H264 Encode Not Support");
          alert('您的此浏览器不支持获取媒体设备及媒体流，请切换浏览器')
        }
      });

      var deviceManager = this.livePusher.getDeviceManager();
      // 获取设备列表
      deviceManager.getDevicesList().then(function (data) {
        console.log(data, "data==");
        data.forEach(function (device) {
          if (device.type == "video") {
            console.log("device.deviceId:", device.deviceId);
            console.log("device.deviceName:", device.deviceName);
            that.cameraIdList.push(device.deviceId)
            if (device.deviceName.toLowerCase().includes("front")) {
              that.frontCameraId = device.deviceId;
            } else if (
              device.deviceName.toLowerCase().includes("back") ||
              device.deviceName.toLowerCase().includes("rear")
            ) {
              that.backCameraId = device.deviceId;
            } else {
              that.cameraId = device.deviceId;
            }
          }
        });

        // 设置视频质量
        that.livePusher.setVideoQuality("1080p");
        // 设置音频质量
        that.livePusher.setAudioQuality("standard");
        // 自定义设置帧率
        that.livePusher.setProperty("setVideoFPS", 25);
        // 设置码率
        that.livePusher.setProperty("setVideoBitrate", 2500);

        // 打开麦克风
        that.livePusher.startMicrophone();

        // 采集完摄像头画面后自动推流
        that.livePusher.startCamera(that.applyCamerId);
        // await this.livePusher.startCamera();

        console.log(that.pushUrl, "that.pushUrl");
        // that.livePusher.startPush(that.pushUrl);

        var hasVideo = false;
        var isPush = false;
        var hasAudio = false;
        that.livePusher.setObserver({
          onCaptureFirstAudioFrame: function () {
            hasAudio = true;
            if (hasVideo && !isPush) {
              isPush = true;
              that.livePusher.startPush(that.pushUrl);
              that.isCloseLive = false;
            }
          },
          onCaptureFirstVideoFrame: function () {
            hasVideo = true;
            if (hasAudio && !isPush) {
              isPush = true;
              that.livePusher.startPush(that.pushUrl);
              that.isCloseLive = false;
            }
          },
        });

        // 推流的回调通知，
      });
    },
    handleBack() {
        this.$confirm("此操作将关闭直播, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.backLive();
            this.isCloseLive = true;
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.$router.go(-1);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
    },
    async backLive() {
      // 停止直播推流：
      this.livePusher.stopPush();
      // 关闭摄像头
      this.livePusher.stopCamera();
      // 关闭麦克风
      this.livePusher.stopMicrophone();

      const admin = JSON.parse(localStorage.getItem("schoolInfo"));
      const params = {
        user_type: admin.user_type,
        school_live_id: this.id,
        match_id: this.match_id,
      };
      const { data, code } = await this.$get("/match/school/endPush", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.live_contain {
  width: 100%;
  height: 100vh;
}

.back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.game_head {
  background-color: #212526;
}

.live_content {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #000;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.LogOut {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
